<template>
  <div class="page-basic-layout">
    <Header />
    <div class="page-basic-body">
      <Sider></Sider>
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/LayoutHeader.vue";
import Footer from "@/components/LayoutFooter.vue";
import Sider from "@/components/LayoutSider.vue";
export default {
  components: {
    Header,
    Footer,
    Sider,
  },
  data() {
    return {};
  },
  created() {},
};
</script>

<style lang="less" scpoed>
.page-basic-layout {
  min-height: 100vh;
  min-width: 1080px;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
}
.page-basic-body {
  flex: auto;
  background-color: #f5f5f5;
  min-height: 680px;
  width: 1240px;
  margin: 0 auto;
  display: flex;
  margin-top: 30px;
  padding-bottom: 50px;
}
</style>
