<template>
  <div class="sider-container">
    <el-menu active-text-color="#0fc700" :default-active="$route.name">
      <el-menu-item
        v-for="(item, index) in siderNav"
        :key="index"
        :index="item.name"
        @click="jump(item.name)"
      >
        <i :class="item.icon"></i>
        <span slot="title">{{ item.label }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      siderNav: [
        {
          label: "个人信息",
          icon: "el-icon-user",
          name: "JkxyUserCenter",
        },
        {
          label: "订单信息",
          icon: "el-icon-s-order",
          name: "JkxyOrder",
        },
      ],
    };
  },
  created() {},
  methods: {
    jump(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="less" scoped>
.sider-container {
  width: 270px;
  margin-right: 20px;
}
</style>
