<template>
  <div>
    <div class="footer-section">
      <div class="container-1240">
        <div class="footer-vertical_top">
          <div class="logo-container">
            <router-link to="/">
              <img class="logo-header" src="@/assets/images/logo_login.png" />
            </router-link>
            <div class="logo-desc">面向未来的技术人才教育</div>
            <div class="phone-container">
              <div class="phone-title">客服电话</div>
              <div class="phone-number">010-86468343</div>
              <a
                :href="helpHost + '/contact.html'"
                class="footer-button phone-contact"
                target="_blank"
              >
                联系我们
              </a>
            </div>
          </div>
          <nav class="nav-container">
            <div class="nav-list">
              <div class="item-title">课程</div>
              <router-link to="/plus" class="nav-item"> 实战营 </router-link>
              <router-link to="/course" class="nav-item"> 视频课 </router-link>
            </div>
            <div class="nav-list">
              <div class="item-title">职业</div>
              <router-link v-if="false" to="" class="nav-item">
                职业方向
              </router-link>
              <router-link to="/evaluation" class="nav-item">
                个性定制
              </router-link>
            </div>
            <div class="nav-list">
              <div class="item-title">关于</div>
              <a :href="helpHost" target="_blank" class="nav-item">关于极客</a>
              <a
                :href="helpHost + '/join.html'"
                target="_blank"
                class="nav-item"
                >加入我们</a
              >
            </div>
            <div class="nav-list">
              <div class="item-title">合作</div>
              <router-link to="/one" class="nav-item"> 企业合作 </router-link>
              <a
                :href="fuwuHost + '/welcome/apply'"
                target="_blank"
                class="nav-item"
                >讲师合作</a
              >
            </div>
            <div class="nav-list">
              <div class="item-title">支持</div>
              <a :href="helpHost + '/pay.html'" target="_blank" class="nav-item"
                >常见问题</a
              >
              <a v-if="false" href="javascript:;" class="nav-item">帮助中心</a>
              <a
                href="http://u97m2siwlpv9xejc.mikecrm.com/OvTVwid"
                target="_blank"
                class="nav-item"
                >意见反馈</a
              >
            </div>
          </nav>
          <div class="cooperate-container">
            <div class="footer-sub-title">服务与合作</div>
            <div class="cooperate-logos">
              <template v-for="(logo, index) in cooperateLogos">
                <img :key="index" :src="logo.url" alt="logo.name" />
              </template>
            </div>
          </div>
          <div class="serve-container serve-session">
            <div class="footer-sub-title">服务与合作</div>
            <router-link to="/one" class="serve-button footer-button">
              <i class="enterprise-icon icon" />
              企业人才云
            </router-link>
            <div class="serve-session-tip">成熟方案提高技术团队开发效率</div>
            <router-link to="/edu" class="serve-button footer-button">
              <i class="school-icon icon" />
              高校学习云
            </router-link>
            <div class="serve-session-tip">引进企业项目助力新工科</div>
          </div>
        </div>
        <div class="footer-vertical_bottom">
          <div class="social-container">
            <!-- <a href="https://mp.weixin.qq.com/s?__biz=MjM5ODE0MTM1MA==&mid=502747025&idx=1&sn=cc8f19f1dde60e2ccb48328282b63dff&chksm=3ecca35809bb2a4eb48e8afa905ed23db404d6de6204d60b45106a61eaeab83ff5e4d06df751#rd" target="_blank" class="icon wechat-icon" /> -->
            <a
              href="https://weibo.com/jikexueyuan"
              target="_blank"
              class="icon sina-icon"
            />
            <a href="javascript:;" class="icon twitter-icon" />
            <a href="javascript:;" class="icon github-icon" />
          </div>
          <div class="copyright-container">
            Copyright © 2013-2020 jiker.com All Rights Reserved.
            <a
              style="color: #8e8e8e"
              href="http://beian.miit.gov.cn/"
              target="_blank"
              >粤ICP备19154936号</a
            >
            京公网安备11010802013056
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript" scoped="">
const HELP_HOST = "https://help" + process.env.COOKIE_DOMAIN;
const FUWU_HOST = "https://fuwu" + process.env.COOKIE_DOMAIN;
export default {
  data() {
    return {
      helpHost: HELP_HOST,
      fuwuHost: FUWU_HOST,
      cooperateLogos: [
        {
          url: require("@/assets/images/footer/microsoft-logo.png"),
          name: "microsoft",
        },
        {
          url: require("@/assets/images/footer/tengxunyun-logo.png"),
          name: "tengxunyun",
        },
        {
          url: require("@/assets/images/footer/ualcomm-logo.png"),
          name: "ualcomm",
        },
        {
          url: require("@/assets/images/footer/tuling-logo.png"),
          name: "tuling",
        },
        {
          url: require("@/assets/images/footer/chukong-logo.png"),
          name: "chukong",
        },
        {
          url: require("@/assets/images/footer/shengce-logo.png"),
          name: "shengce",
        },
        {
          url: require("@/assets/images/footer/amd-logo.png"),
          name: "amd",
        },
        {
          url: require("@/assets/images/footer/juhe-logo.png"),
          name: "juhe",
        },
        {
          url: require("@/assets/images/footer/qingyun-logo.png"),
          name: "qingyun",
        },
        {
          url: require("@/assets/images/footer/tengxun-logo.png"),
          name: "tengxun",
        },
        {
          url: require("@/assets/images/footer/zhidahao-logo.png"),
          name: "zhidahao",
        },
        {
          url: require("@/assets/images/footer/tetin-logo.png"),
          name: "tetin",
        },
        {
          url: require("@/assets/images/footer/kedaxunfei-logo.png"),
          name: "kedaxunfei",
        },
      ],
    };
  },
};
</script>

<style type="text/css" lang="less" scoped>
.footer-section {
  background-color: #252222;
  padding: 50px 100px 57px;
  text-align: center;
  font-weight: 400;
}
.footer-sub-title {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  text-align: left;
  color: #fff;
}
.footer-button {
  display: inline-block;
  height: 43px;
  background-color: #333;
  text-align: center;
  font-size: 12px;
  line-height: 43px;
  color: rgba(255, 255, 255, 1);
  transition: 0.2s ease;
  &:hover {
    background-color: #555;
  }
  .icon {
    margin-right: 6px;
    position: relative;
    top: 2px;
    width: 18px;
    height: 15px;
    display: inline-block;
  }
}
.footer-vertical_top {
  display: flex;
  align-items: flex-start;
  padding-bottom: 34px;
  border-bottom: 1px solid #414141;
  .logo-container {
    text-align: left;
    margin-right: 128px;
    .logo-header {
      width: 156px;
      height: 57px;
      margin-bottom: 15px;
    }
    .logo-desc {
      height: 17px;
      line-height: 17px;
      font-size: 12px;
      color: rgba(187, 187, 187, 1);
      letter-spacing: 2px;
      margin-bottom: 40px;
    }
  }
  .phone-container {
    text-align: left;
    .phone-title {
      height: 22px;
      margin-bottom: 6px;
      line-height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
    }
    .phone-number {
      height: 28px;
      line-height: 28px;
      font-size: 20px;
      color: rgba(213, 213, 213, 1);
      margin-bottom: 11px;
    }
    .phone-contact {
      width: 144px;
    }
  }
  .nav-container {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    margin-right: 60px;
    .nav-list {
      display: flex;
      margin-bottom: 20px;
      flex-direction: row;
      margin-right: 0;
      .item-title {
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        margin-right: 20px;
      }
      .nav-item {
        height: 20px;
        line-height: 20px;
        color: #d5d5d5;
        font-size: 14px;
        font-weight: 400;
        margin-right: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #fff;
        }
      }
      a {
        display: inline-block;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.cooperate-container {
  width: 383px;
  margin-right: 54px;
  .cooperate-logos {
    display: flex;
    width: 383px;
    flex-wrap: wrap;
  }
  img {
    display: inline-block;
    height: 25px;
    width: auto;
    margin-bottom: 5px;
  }
}
.serve-container {
  text-align: left;
  width: 144px;
  .serve-button {
    width: 144px;
    margin-bottom: 8px;
  }
  .serve-session-tip {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 20px;
    color: #d5d5d5;
  }
  .enterprise-icon {
    background: url(~@/assets/images/footer/enterprise-icon.svg);
  }
  .school-icon {
    background: url(~@/assets/images/footer/school-icon.svg);
  }
}
.footer-vertical_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0;
  .social-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      display: inline-block;
      width: 32px;
      height: 32px;
      background-color: rgba(160, 160, 160, 1);
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
      }
    }
    .wechat-icon {
      background: url("../assets/images/icon/icon-wechat.svg") 0 0 no-repeat;
    }
    .sina-icon {
      background: url("../assets/images/icon/icon-sina.svg") 0 0 no-repeat;
    }
    .twitter-icon {
      background: url("../assets/images/icon/icon-twitter.svg") 0 0 no-repeat;
    }
    .github-icon {
      background: url("../assets/images/icon/icon-github.svg") 0 0 no-repeat;
    }
  }
  .copyright-container {
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    font-weight: 300;
    line-height: 17px;
    color: rgba(142, 142, 142, 1);
  }
}
</style>
