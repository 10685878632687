<template>
  <header class="page-header">
    <div class="default-header-outer">
      <div
        :class="[
          'default-header',
          showDefaultHeader
            ? inHeaderHeight
              ? ''
              : 'transform-Default-in'
            : inHeaderHeight
            ? ''
            : 'transform-Default-out',
        ]"
      >
        <div class="container-1240 header-content">
          <router-link to="/">
            <img class="header-logo" src="@/assets/images/logo_login.png" />
          </router-link>
          <div class="header-right">
            <div class="header-right-top">
              <div class="header-right-top-right">
                <el-dropdown style="height: 100%" @command="handleCommand">
                  <div class="bar-info-container">
                    <div class="userInfo-avatar-container">
                      <img class="userInfo-avatar" :src="userInfo.avatar" />
                    </div>
                    <span class="userInfo-name">{{ userInfo.uname }}</span>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item
                      icon="el-icon-video-camera"
                      command="handleMyLearn"
                    >
                      学习中心
                    </el-dropdown-item>
                    <el-dropdown-item
                      icon="el-icon-setting"
                      command="handleMySetting"
                    >
                      账号设置
                    </el-dropdown-item> -->
                    <el-dropdown-item
                      icon="el-icon-video-pause"
                      command="handleLogout"
                      divided
                    >
                      退出
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script type="text/javascript">
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchVal: "",
      showThinHeader: false,
      showSearch: false,
      showServe: false,
      showCompanyMenu: false,
      showDefaultHeader: true,
      inHeaderHeight: true,
      beforeScrollTop: 0,
      scrollTimeout: null,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    $route() {
      this.showServe = false;
      this.showSearch = false;
    },
  },
  mounted() {
    this.beforeScrollTop = document.body.scrollTop;
    // document.addEventListener("scroll", this.handleScroll, true);
    document.addEventListener("click", this.handlePageClick, false);
  },
  methods: {
    handlePageClick(e) {
      if (
        e.target &&
        e.target.closest(".search-input") !==
          (this.$refs["search-input"] && this.$refs["search-input"].$el)
      ) {
        this.showSearch = false;
        this.showServe = false;
      }
    },
    handleScroll() {
      // eslint-disable-next-line prefer-const
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout);
      }
      if (scrollTop - this.beforeScrollTop > 0) {
        this.scrollTimeout = setTimeout(() => {
          this.showDefaultHeader = false;
          if (scrollTop >= 110) {
            this.showThinHeader = true;
            // this.inHeaderHeight = false
          } else {
            this.inHeaderHeight = true;
          }
        }, 200);
      } else {
        this.scrollTimeout = setTimeout(() => {
          this.showThinHeader = false;
          this.showDefaultHeader = true;
          if (scrollTop >= 110) {
            this.inHeaderHeight = false;
          } else {
            this.inHeaderHeight = true;
          }
        }, 200);
      }
      this.beforeScrollTop = scrollTop;
    },
    handleCommand(command) {
      this[command]();
    },
    handleMyLearn() {
      this.$router.push("/my/course");
    },
    handleLogout() {
      this.$store.dispatch("logout");
    },
    handleMySetting() {
      this.$router.push("/setting/my");
    },
  },
};
</script>
<style type="text/css" lang="less" scoped>
/deep/.el-input__inner {
  background-color: #f5f5f5;
  border-radius: 4px 0 0 4px;
  border: none;
}
/deep/.el-input--small .el-input__inner {
  height: 36px;
  line-height: 36px;
}
/deep/.el-input-group__append {
  border: none;
  background-color: #d8d8d8;
  &:hover {
    background-color: #0fc700;
    color: #fff;
  }
}
@keyframes moreWidth {
  from {
    width: 0;
  }
  to {
    width: 813px;
  }
}
.page-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  z-index: 200;
  font-weight: 400;

  .nav-icon {
    width: 17px;
    height: 20px;
    margin-right: 8px;
    &.feishu {
      width: 20px;
    }
  }
  .nav-company {
    &.showMenu {
      color: #0fc700 !important;
    }
  }
  @keyframes companyBorder {
    from {
      border-top: 1px solid #eaeaea;
    }
    to {
      border-top: 1px solid #fff;
    }
  }
  .default-header-outer {
    z-index: 100;
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }
  .default-header {
    background: #fff;
    .header-logo {
      width: 120px;
      height: 45px;
      //   margin-right: 54px;
    }
    &.transform-Default-in {
      position: fixed;
      top: -110px;
      left: 0;
      right: 0;
      min-width: 1440px;
      background: #fff;
      transform: translateY(110px);
      transition: all 500ms;
      background-color: #fff;
    }
    &.transform-Default-out {
      position: fixed;
      top: -110px;
      left: 0;
      right: 0;
      opacity: 0;
      transform: translateY(0px);
      transition: all 300ms;
    }
    .header-right {
      &-top {
        display: flex;
        justify-content: space-between;
        &-right {
          display: flex;
        }
      }
      .search-input {
        width: 756px;
      }
      &-bottom {
        display: flex;
        justify-content: space-between;
        padding-top: 19px;
      }
      .header-bottom-session {
        &-left {
          display: flex;
          .header-nav-item {
            margin-right: 40px;
            padding-bottom: 18px;
            color: #333;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
        &-right {
          display: flex;
          align-items: center;
          .header-nav-item {
            display: flex;
            color: #858585;
            margin-left: 40px;
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 18px;
          }
        }
      }
    }
  }
}
</style>
