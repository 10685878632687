var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"page-header"},[_c('div',{staticClass:"default-header-outer"},[_c('div',{class:[
        'default-header',
        _vm.showDefaultHeader
          ? _vm.inHeaderHeight
            ? ''
            : 'transform-Default-in'
          : _vm.inHeaderHeight
          ? ''
          : 'transform-Default-out',
      ]},[_c('div',{staticClass:"container-1240 header-content"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"header-logo",attrs:{"src":require("@/assets/images/logo_login.png")}})]),_c('div',{staticClass:"header-right"},[_c('div',{staticClass:"header-right-top"},[_c('div',{staticClass:"header-right-top-right"},[_c('el-dropdown',{staticStyle:{"height":"100%"},on:{"command":_vm.handleCommand}},[_c('div',{staticClass:"bar-info-container"},[_c('div',{staticClass:"userInfo-avatar-container"},[_c('img',{staticClass:"userInfo-avatar",attrs:{"src":_vm.userInfo.avatar}})]),_c('span',{staticClass:"userInfo-name"},[_vm._v(_vm._s(_vm.userInfo.uname))])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-video-pause","command":"handleLogout","divided":""}},[_vm._v(" 退出 ")])],1)],1)],1)])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }